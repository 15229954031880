import { render, staticRenderFns } from "./dashboardLayout.vue?vue&type=template&id=b205c01c"
import script from "./dashboardLayout.vue?vue&type=script&lang=js"
export * from "./dashboardLayout.vue?vue&type=script&lang=js"
import style0 from "./dashboardLayout.vue?vue&type=style&index=0&id=b205c01c&prod&lang=scss"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Header: require('/home/deploy/www/new-front/components/Header.vue').default,FooterMain: require('/home/deploy/www/new-front/components/FooterMain.vue').default})
