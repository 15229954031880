// import Vue from 'vue';
// import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-2';

// export default function() {
//     Vue.use(TawkMessengerVue, {
//         propertyId: '586be4c67bb7bf7f52652ebb',
//         widgetId: 'default'
//     });
// }

import Vue from 'vue';
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-2';

export default ({ app }) => {
    setTimeout(() => {
        Vue.use(TawkMessengerVue, {
            propertyId: '586be4c67bb7bf7f52652ebb',
            widgetId: 'default',
        });
    }, 30000); // Задержка в миллисекундах, здесь 3000 мс (3 секунды)
};