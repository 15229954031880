import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _a84c6006 = () => interopDefault(import('../pages/about-us.vue' /* webpackChunkName: "pages/about-us" */))
const _fee0c3f8 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _60321f46 = () => interopDefault(import('../pages/career.vue' /* webpackChunkName: "pages/career" */))
const _64a34a51 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _a89fe70c = () => interopDefault(import('../pages/disclaimer.vue' /* webpackChunkName: "pages/disclaimer" */))
const _32fdb23f = () => interopDefault(import('../pages/ebook.vue' /* webpackChunkName: "pages/ebook" */))
const _4ba37ce8 = () => interopDefault(import('../pages/error500.vue' /* webpackChunkName: "pages/error500" */))
const _2334faca = () => interopDefault(import('../pages/example.vue' /* webpackChunkName: "pages/example" */))
const _295e0a72 = () => interopDefault(import('../pages/faq.vue' /* webpackChunkName: "pages/faq" */))
const _f16a2484 = () => interopDefault(import('../pages/gdpr.vue' /* webpackChunkName: "pages/gdpr" */))
const _56f95c67 = () => interopDefault(import('../pages/our-missions.vue' /* webpackChunkName: "pages/our-missions" */))
const _73fea076 = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _28818c74 = () => interopDefault(import('../pages/products/index.vue' /* webpackChunkName: "pages/products/index" */))
const _4ad6b218 = () => interopDefault(import('../pages/products-services.vue' /* webpackChunkName: "pages/products-services" */))
const _009a02f0 = () => interopDefault(import('../pages/remove-info.vue' /* webpackChunkName: "pages/remove-info" */))
const _5870ff34 = () => interopDefault(import('../pages/sitemap.vue' /* webpackChunkName: "pages/sitemap" */))
const _93451310 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _80c0075a = () => interopDefault(import('../pages/testimonials.vue' /* webpackChunkName: "pages/testimonials" */))
const _6ea30f8f = () => interopDefault(import('../pages/under-maintenance.vue' /* webpackChunkName: "pages/under-maintenance" */))
const _34ac0ee3 = () => interopDefault(import('../pages/account/blog.vue' /* webpackChunkName: "pages/account/blog" */))
const _4ed18723 = () => interopDefault(import('../pages/account/dashboard.vue' /* webpackChunkName: "pages/account/dashboard" */))
const _363fbe91 = () => interopDefault(import('../pages/account/e-books.vue' /* webpackChunkName: "pages/account/e-books" */))
const _ab740ac2 = () => interopDefault(import('../pages/account/listing-locator/index.vue' /* webpackChunkName: "pages/account/listing-locator/index" */))
const _471a4eb9 = () => interopDefault(import('../pages/account/monthly-plans.vue' /* webpackChunkName: "pages/account/monthly-plans" */))
const _48e1d812 = () => interopDefault(import('../pages/account/notifications.vue' /* webpackChunkName: "pages/account/notifications" */))
const _bd15c61c = () => interopDefault(import('../pages/account/plans-pricing.vue' /* webpackChunkName: "pages/account/plans-pricing" */))
const _17f82578 = () => interopDefault(import('../pages/account/profile.vue' /* webpackChunkName: "pages/account/profile" */))
const _179d5814 = () => interopDefault(import('../pages/account/search-credits.vue' /* webpackChunkName: "pages/account/search-credits" */))
const _10203b88 = () => interopDefault(import('../pages/account/search-report.vue' /* webpackChunkName: "pages/account/search-report" */))
const _d239f404 = () => interopDefault(import('../pages/account/tinder-blaster-credits.vue' /* webpackChunkName: "pages/account/tinder-blaster-credits" */))
const _f35cc0be = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _da1f6cca = () => interopDefault(import('../pages/auth/register.vue' /* webpackChunkName: "pages/auth/register" */))
const _79e05a0f = () => interopDefault(import('../pages/products/find-someone-cheating-online-for-free.vue' /* webpackChunkName: "pages/products/find-someone-cheating-online-for-free" */))
const _c379e27e = () => interopDefault(import('../pages/products/listing-locator.vue' /* webpackChunkName: "pages/products/listing-locator" */))
const _52eef6ef = () => interopDefault(import('../pages/products/search-on-dating-sites.vue' /* webpackChunkName: "pages/products/search-on-dating-sites" */))
const _11311678 = () => interopDefault(import('../pages/products/search-on-live-adult-streams.vue' /* webpackChunkName: "pages/products/search-on-live-adult-streams" */))
const _3211fda9 = () => interopDefault(import('../pages/products/search-on-social-media-and-mobile-chat.vue' /* webpackChunkName: "pages/products/search-on-social-media-and-mobile-chat" */))
const _fc0e2846 = () => interopDefault(import('../pages/products/search-on-tinder.vue' /* webpackChunkName: "pages/products/search-on-tinder" */))
const _54764eb2 = () => interopDefault(import('../pages/products/search-on-xxx-websites.vue' /* webpackChunkName: "pages/products/search-on-xxx-websites" */))
const _3764868f = () => interopDefault(import('../pages/account/billing/cancel.vue' /* webpackChunkName: "pages/account/billing/cancel" */))
const _64a1b9bb = () => interopDefault(import('../pages/account/billing/invoices.vue' /* webpackChunkName: "pages/account/billing/invoices" */))
const _c3bf6bb4 = () => interopDefault(import('../pages/account/billing/payment-methods.vue' /* webpackChunkName: "pages/account/billing/payment-methods" */))
const _21ea21df = () => interopDefault(import('../pages/account/subscriptions/manage.vue' /* webpackChunkName: "pages/account/subscriptions/manage" */))
const _0b8ce223 = () => interopDefault(import('../pages/auth/password/email/index.vue' /* webpackChunkName: "pages/auth/password/email/index" */))
const _409f2ad5 = () => interopDefault(import('../pages/api/auth/login/facebook/callback.vue' /* webpackChunkName: "pages/api/auth/login/facebook/callback" */))
const _ec53fbfc = () => interopDefault(import('../pages/api/auth/login/google/callback.vue' /* webpackChunkName: "pages/api/auth/login/google/callback" */))
const _062f0f9d = () => interopDefault(import('../pages/api/auth/login/linkedin/callback.vue' /* webpackChunkName: "pages/api/auth/login/linkedin/callback" */))
const _7d8c52ca = () => interopDefault(import('../pages/api/auth/login/twitter/callback.vue' /* webpackChunkName: "pages/api/auth/login/twitter/callback" */))
const _0c5fb843 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _463d6c38 = () => interopDefault(import('../pages/account/subscriptions/payments/view/_slug.vue' /* webpackChunkName: "pages/account/subscriptions/payments/view/_slug" */))
const _4ebc6264 = () => interopDefault(import('../pages/auth/password/reset/_slug.vue' /* webpackChunkName: "pages/auth/password/reset/_slug" */))
const _aee14d52 = () => interopDefault(import('../pages/account/listing-locator/_slug.vue' /* webpackChunkName: "pages/account/listing-locator/_slug" */))
const _c6054096 = () => interopDefault(import('../pages/account/verify/_slug.vue' /* webpackChunkName: "pages/account/verify/_slug" */))
const _11b958c4 = () => interopDefault(import('../pages/blog/author/_slug.vue' /* webpackChunkName: "pages/blog/author/_slug" */))
const _45494ef7 = () => interopDefault(import('../pages/blog/category/_slug.vue' /* webpackChunkName: "pages/blog/category/_slug" */))
const _4a6828f2 = () => interopDefault(import('../pages/blog/tag/_slug.vue' /* webpackChunkName: "pages/blog/tag/_slug" */))
const _5b7ef32a = () => interopDefault(import('../pages/tinder/search/_firstSearch/index.vue' /* webpackChunkName: "pages/tinder/search/_firstSearch/index" */))
const _5eec35ba = () => interopDefault(import('../pages/tinder/search/_firstSearch/_slug.vue' /* webpackChunkName: "pages/tinder/search/_firstSearch/_slug" */))
const _7ed8fcbc = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _7afc0452 = () => interopDefault(import('../pages/scan/_slug.vue' /* webpackChunkName: "pages/scan/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about-us",
    component: _a84c6006,
    name: "about-us"
  }, {
    path: "/blog",
    component: _fee0c3f8,
    name: "blog"
  }, {
    path: "/career",
    component: _60321f46,
    name: "career"
  }, {
    path: "/contact",
    component: _64a34a51,
    name: "contact"
  }, {
    path: "/disclaimer",
    component: _a89fe70c,
    name: "disclaimer"
  }, {
    path: "/ebook",
    component: _32fdb23f,
    name: "ebook"
  }, {
    path: "/error500",
    component: _4ba37ce8,
    name: "error500"
  }, {
    path: "/example",
    component: _2334faca,
    name: "example"
  }, {
    path: "/faq",
    component: _295e0a72,
    name: "faq"
  }, {
    path: "/gdpr",
    component: _f16a2484,
    name: "gdpr"
  }, {
    path: "/our-missions",
    component: _56f95c67,
    name: "our-missions"
  }, {
    path: "/privacy-policy",
    component: _73fea076,
    name: "privacy-policy"
  }, {
    path: "/products",
    component: _28818c74,
    name: "products"
  }, {
    path: "/products-services",
    component: _4ad6b218,
    name: "products-services"
  }, {
    path: "/remove-info",
    component: _009a02f0,
    name: "remove-info"
  }, {
    path: "/sitemap",
    component: _5870ff34,
    name: "sitemap"
  }, {
    path: "/terms",
    component: _93451310,
    name: "terms"
  }, {
    path: "/testimonials",
    component: _80c0075a,
    name: "testimonials"
  }, {
    path: "/under-maintenance",
    component: _6ea30f8f,
    name: "under-maintenance"
  }, {
    path: "/account/blog",
    component: _34ac0ee3,
    name: "account-blog"
  }, {
    path: "/account/dashboard",
    component: _4ed18723,
    name: "account-dashboard"
  }, {
    path: "/account/e-books",
    component: _363fbe91,
    name: "account-e-books"
  }, {
    path: "/account/listing-locator",
    component: _ab740ac2,
    name: "account-listing-locator"
  }, {
    path: "/account/monthly-plans",
    component: _471a4eb9,
    name: "account-monthly-plans"
  }, {
    path: "/account/notifications",
    component: _48e1d812,
    name: "account-notifications"
  }, {
    path: "/account/plans-pricing",
    component: _bd15c61c,
    name: "account-plans-pricing"
  }, {
    path: "/account/profile",
    component: _17f82578,
    name: "account-profile"
  }, {
    path: "/account/search-credits",
    component: _179d5814,
    name: "account-search-credits"
  }, {
    path: "/account/search-report",
    component: _10203b88,
    name: "account-search-report"
  }, {
    path: "/account/tinder-blaster-credits",
    component: _d239f404,
    name: "account-tinder-blaster-credits"
  }, {
    path: "/auth/login",
    component: _f35cc0be,
    name: "auth-login"
  }, {
    path: "/auth/register",
    component: _da1f6cca,
    name: "auth-register"
  }, {
    path: "/products/find-someone-cheating-online-for-free",
    component: _79e05a0f,
    name: "products-find-someone-cheating-online-for-free"
  }, {
    path: "/products/listing-locator",
    component: _c379e27e,
    name: "products-listing-locator"
  }, {
    path: "/products/search-on-dating-sites",
    component: _52eef6ef,
    name: "products-search-on-dating-sites"
  }, {
    path: "/products/search-on-live-adult-streams",
    component: _11311678,
    name: "products-search-on-live-adult-streams"
  }, {
    path: "/products/search-on-social-media-and-mobile-chat",
    component: _3211fda9,
    name: "products-search-on-social-media-and-mobile-chat"
  }, {
    path: "/products/search-on-tinder",
    component: _fc0e2846,
    name: "products-search-on-tinder"
  }, {
    path: "/products/search-on-xxx-websites",
    component: _54764eb2,
    name: "products-search-on-xxx-websites"
  }, {
    path: "/account/billing/cancel",
    component: _3764868f,
    name: "account-billing-cancel"
  }, {
    path: "/account/billing/invoices",
    component: _64a1b9bb,
    name: "account-billing-invoices"
  }, {
    path: "/account/billing/payment-methods",
    component: _c3bf6bb4,
    name: "account-billing-payment-methods"
  }, {
    path: "/account/subscriptions/manage",
    component: _21ea21df,
    name: "account-subscriptions-manage"
  }, {
    path: "/auth/password/email",
    component: _0b8ce223,
    name: "auth-password-email"
  }, {
    path: "/api/auth/login/facebook/callback",
    component: _409f2ad5,
    name: "api-auth-login-facebook-callback"
  }, {
    path: "/api/auth/login/google/callback",
    component: _ec53fbfc,
    name: "api-auth-login-google-callback"
  }, {
    path: "/api/auth/login/linkedin/callback",
    component: _062f0f9d,
    name: "api-auth-login-linkedin-callback"
  }, {
    path: "/api/auth/login/twitter/callback",
    component: _7d8c52ca,
    name: "api-auth-login-twitter-callback"
  }, {
    path: "/",
    component: _0c5fb843,
    name: "index"
  }, {
    path: "/account/subscriptions/payments/view/:slug?",
    component: _463d6c38,
    name: "account-subscriptions-payments-view-slug"
  }, {
    path: "/auth/password/reset/:slug?",
    component: _4ebc6264,
    name: "auth-password-reset-slug"
  }, {
    path: "/account/listing-locator/:slug?",
    component: _aee14d52,
    name: "account-listing-locator-slug"
  }, {
    path: "/account/verify/:slug?",
    component: _c6054096,
    name: "account-verify-slug"
  }, {
    path: "/blog/author/:slug?",
    component: _11b958c4,
    name: "blog-author-slug"
  }, {
    path: "/blog/category/:slug?",
    component: _45494ef7,
    name: "blog-category-slug"
  }, {
    path: "/blog/tag/:slug?",
    component: _4a6828f2,
    name: "blog-tag-slug"
  }, {
    path: "/tinder/search/:firstSearch",
    component: _5b7ef32a,
    name: "tinder-search-firstSearch"
  }, {
    path: "/tinder/search/:firstSearch?/:slug",
    component: _5eec35ba,
    name: "tinder-search-firstSearch-slug"
  }, {
    path: "/blog/:slug",
    component: _7ed8fcbc,
    name: "blog-slug"
  }, {
    path: "/scan/:slug?",
    component: _7afc0452,
    name: "scan-slug"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
